<template lang="pug">
  Auth(@loggedIn="onLoggedIn")
    Header(:content="headerContent")
    div.wrap-events-complete.f.fh
      ModuleSendEventsComplete
</template>

<style lang="scss" scoped>
.wrap-events-complete {
  width: 100%;
  min-height: 100vh;
}
</style>

<script>
import { createNamespacedHelpers } from 'vuex'
const { mapState: mapStateAuth } = createNamespacedHelpers('auth')
import Auth from '@/components/shared/Auth'
import Header from '@/components/shared/Header.vue'
import ModuleSendEventsComplete from '@/components/module/ModuleSendEventsComplete'

export default {
  components: {
    Auth,
    Header,
    ModuleSendEventsComplete
  },
  data() {
    return {
      headerContent: {
        // title: 'Tmp',
        // left: {
        //   label: 'Home',
        //   icon: 'mdi-home',
        //   to: '/tmp'
        // },
        right: {
          label: '完了',
          color: '#4285f4',
          // icon: 'mdi-account',
          to: `/meeting/${this.$route.params.meetingId}/${this.$route.params.inviteeEmail}`
        }
      }
    }
  },
  computed: {
    ...mapStateAuth(['uid', 'isAnonymous'])
  },
  created() {
    // if (this.isAnonymous) {
    //   this.headerContent.right = {
    //     label: 'Sign up',
    //     color: '#4285f4',
    //     to: `/sign-up/${this.$route.params.inviteeEmail}`
    //   }
    // }
  },
  methods: {
    onLoggedIn() {
      // if (this.isAnonymous) {
      //   this.headerContent.right = {
      //     label: 'Sign up',
      //     color: '#4285f4',
      //     to: `/sign-up/${this.$route.params.inviteeEmail}`
      //   }
      // }
    }
  }
}
</script>
