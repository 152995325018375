<template lang="pug">
  div.wrap-module-send-events-complete.f.fh
    div.send-events-complete-content
      div.f.fc.mb8
        v-icon(size="100px") mdi-check-circle-outline
      div
        span.text-center.block.fz12.mb4 日時の送信が完了しました。
        span.text-center.block.fz12.mb4 全員から日程の候補日が取得でき次第
        span.text-center.block.fz12 すぐに調整してお知らせいたします。
      div(v-if="!uid || isAnonymous").mt40
        div.f.fc.mb20
          v-img(width="100" src="/img/illustration/calendar.png")
        span.text-center.block.fz12.mb4 あなたもMeetAwesomeを使うと
        span.text-center.block.fz12.mb20 ミーティングを最短で自動設定できます！
        div.f.fc.mt10
          span(@click="onGettingStarted").btn-to-sign-up.cursor-pointer.fz14.px18.py8 MeetAwesomeを利用してみる
      //- div(v-if="isAnonymous").mt40
        span.text-center.block.fz12.mb4 あなたもMeetAwesomeを使うと
        span.text-center.block.fz12.mb4 ミーティングを最短で自動設定できます！
        div.f.fc.mt10
          span(@click="onGettingStarted").btn-to-sign-up.cursor-pointer.fz14.px18.py8 MeetAwesomeを利用してみる
      //- div(v-else).mt40
        span(@click="logout").sign-out.text-center.block.fz12 サインアウト
</template>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';

.wrap-module-send-events-complete {
  width: 100%;
  min-height: 100vh;
  padding-top: $header_height;
  .send-events-complete-content {
    // width: $base_width_percent;
    max-width: $base_max_width_px;
    margin: 0 auto;
    .btn-to-sign-up {
      border-radius: 3px;
      background: $active_color;
      color: #fff;
    }
    .sign-out {
      color: $secondary_text_color;
    }
  }
}
</style>

<script>
import { createNamespacedHelpers } from 'vuex'
const { mapState: mapStateAuth, mapActions: mapActionsAuth } = createNamespacedHelpers('auth')

export default {
  components: {
  },
  computed: {
    ...mapStateAuth(['uid', 'isAnonymous'])
  },
  data () {
    return {
      
    }
  },
  methods: {
    ...mapActionsAuth(['signOut']),
    logout () {
      this.signOut()
      setTimeout(() => {
        this.$router.push('/sign-in')
      }, 400)
    },
    onGettingStarted () {
      // mixpannel
      // sendTrackingEvent(`${this.$route.path}:onGettingStarted`, {}, this.uid)
      this.$root.$emit('sendTrackingEventByClick', { 
        message: 'click_start_app',
        option: { meetingId: this.$route.params.meetingId }
      })
      this.$router.push(`/sign-up/${this.$route.params.inviteeEmail}`)
    }
  }
}
</script>
